import styles from './Links.module.css';
import Header from '../components/Header';
import { BsFacebook, BsGlobe2, BsYoutube, BsInstagram } from 'react-icons/bs';
import { useEffect, useState } from 'react';

const LinksPage = () => {

  const [links, setLinks] = useState();

  useEffect(() => {
    fecthLinks();
  }, [])


  const fecthLinks = async () => {
    var requestOptions = {
      method: 'GET',
      redirect: 'follow'
    };
    const response = await fetch('https://api.glono.in/api/v1/config/links', requestOptions);
    const data = await response.json();
    setLinks(data.data);
  }

  console.log(links)

  if (!links) return <div className={styles.loading}>Loading...</div>

  return (
    <main className={styles.main}>
      <div className={styles.container}>
        <div>
          <Header />
        </div>
        <div>
          <h3 className={styles.heading}>Our App</h3>
          <div onClick={() => window.open(links.app_android)} className={styles.button}>
            <div className={styles.linkA}>Glono Play Store</div>
          </div>
          <div onClick={() => window.open(links.app_ios)} className={styles.button}>
            <div className={styles.linkA}>Glono App Store</div>
          </div>
        </div>
        <div>
          <h3 className={styles.heading}>Our Website</h3>
          <div onClick={() => window.open(links.website, "_blank")} className={styles.button}>
            <div className={styles.linkA}>JCBL Accessories</div>
          </div>
          <div onClick={() => window.open(links.blog, "_blank")} className={styles.button}>
            <p className={styles.linkA}>Blog</p>
          </div>
        </div>
        <div>
          <h3 className={styles.heading}>Our Social Networks</h3>
          <div onClick={() => window.open(links.instagram)} className={styles.button}>
            <p className={styles.linkA}>Instagram</p>
          </div>
          <div onClick={() => window.open(links.facebook)} className={styles.button}>
            <p className={styles.linkA}>Facebook</p>
          </div>
          <div onClick={() => window.open(links.youtube)} className={styles.button}>
            <p className={styles.linkA}>Youtube</p>
          </div>
        </div>
        <div className={styles.icon}>
          <a className={styles.iconD} rel="noreferrer" href={links.instagram} target='_blank'><BsInstagram className={styles.iconSpan} /></a>
          <a className={styles.iconD} rel="noreferrer" href={links.facebook} target='_blank'><BsFacebook className={styles.iconSpan} /></a>
          <a className={styles.iconD} rel="noreferrer" href={links.youtube} target='_blank'><BsYoutube className={styles.iconSpan} /> </a>
          <a className={styles.iconD} rel="noreferrer" href={links.website} target='_blank'><BsGlobe2 className={styles.iconSpan} /></a>
        </div>
      </div>
    </main>
  );
}

export default LinksPage;