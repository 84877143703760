import React from 'react';
import styles from './header.module.css'

const Header = () => {
    return (
        <header className={styles.header}>
            <img src={'https://jcblaccessories.com/cdn/shop/files/jcbl_final_logo.png?v=1676378693&width=260'} alt="Header_Image" className={styles.headerImage} />
        </header>
    );
};

export default Header;