import React from 'react';
import style from './Header_1.module.css';
import image from '../assets/Vector.png'
import { Link } from 'react-router-dom';

const Header_1 = () => {
  return (
    <div className={style.container}>
      <Link to="/">
    <img src={image} alt="..." />
    </Link> 
  </div>
  )
}

export default Header_1