import React from 'react'

const RedirectionScreen = () => {
    React.useEffect(() => {
        // <script>
        // function redirectToAppStoreOrPlayStore() {
            // Check the user's device type and redirect to the appropriate app store using deep links
            if (/(iPhone|iPad)/i.test(navigator.userAgent)) {
                window.location.href = "https://apps.apple.com/app/glono/id6468880764";
            } else if (/Android/i.test(navigator.userAgent)) {
                window.location.href = "https://play.google.com/store/apps/details?id=com.jcbl.glono&pli=1";
            } else {
                window.location.pathname = "/";
            }

    }, [])
    
  return (
    <div></div>
  )
}

export default RedirectionScreen