import React from 'react';
import ReactDOM from 'react-dom/client';
import './index.css';
import reportWebVitals from './reportWebVitals';
import {
  createBrowserRouter,
  RouterProvider,
} from "react-router-dom";
import Home from './screens/Home';
import LinksPage from './screens/LinksPage';
import About from './screens/About';
import Rewards from './screens/Rewards';
import VipSignup from './screens/VipSignup';
import RedirectionScreen from './screens/RedirectionScreen';
import NewUserData from './screens/NewUserData';

const router = createBrowserRouter([
  {
    path: "/",
    element: <Home />,
  },
  {
    path: "/links",
    element: <LinksPage />,
  },
  {
    path: "/about",
    element: <About />,
  },
  {
    path: "/rewards",
    element: <Rewards />,
  },
  {
    path: "/vip-signup",
    element: <VipSignup />,
  },
  {
    path: "/business-onboard",
    element: <NewUserData />,
  },
  // {
  //   path: 'download_now',
  //   element: <RedirectionScreen />,
  // },
  {
    path: "*",
    element: <RedirectionScreen />,
  },
]);


const root = ReactDOM.createRoot(document.getElementById('root'));
root.render(
  <React.StrictMode>
    <RouterProvider router={router} />
  </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();
