import React, { useState } from "react";
import Header1 from "../components/Header_1";
import vactor from "../assets/Vector-1.svg";
import youtube from "../assets/youtube.png";
import facebook from "../assets/facebook.png";
import instagram from "../assets/instagram.png";
import browser from "../assets/browser.png";
import styled from "./vipSignup.module.css";
import cross from "../assets/delete.png";
import popuplogo from "../assets/Group.png";


const VipSignup = () => {
  const [storeName, setStoreName] = useState("");
  const [email, setEmail] = useState("");
  const [state, setState] = useState("");
  const [city, setCity] = useState("");
  const [formSubmit, setFormSubmit] = useState(false);
  const [emailError, setEmailError] = useState("");
  const checkEmail = (input) => {
    const emailRegex = /^[^\s@]+@[^\s@]+\.[^\s@]+$/;

    if (!emailRegex.test(input)) {
      showError(input, "Please enter valid email address");
      return false;
    } else {
      showSuccess(input);
      return true;
    }
  };

  const showError = (input, message) => {
    // Assuming you have a state variable for error messages
    setEmailError(message);
  };

  const showSuccess = (input) => {
    // Reset the error message
    setEmailError("");
    // Your existing showSuccess logic
  };

  const handleFormSubmit = (e) => {
    e.preventDefault();

    // Reset the error message
    setEmailError("");

    // Check email validation
    const isEmailValid = checkEmail(email);

    if (isEmailValid) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          store_name: storeName,
          email: email,
          state: state,
          city: city,
        }),
      };

      fetch("https://dev-cms.glono.in/api/v2/website/glono_vip", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          setStoreName("");
          setEmail("");
          setState("");
          setCity("");
          setFormSubmit(true);
        })
        .catch((error) => {
          console.log("error", error);
        });
    }
  };


  // disable ========
  const inputDisabled = () => {
    if (storeName === "" || email === "" || state === "" || city === "") {
      return true;
    }
    return false;
  };

  return formSubmit ? (
    <>
      <div className={styled.container}>
        {/* ========= */}
        <div className={styled.success_message}>
          <div className={styled.success_box}>
            <img src={popuplogo} alt="..." className={styled.success_icon}/>
            <div className={styled.submitted}>Thank You For Subscribing Us!</div>
            <div className={styled.submitted_2}>Stay Tuned For More Updates And Offers.</div>
            <button
              onClick={() => setFormSubmit(false)}
              className={styled.delete}
            >
             <img src={cross} alt="..." />
            </button>
          </div>
        </div>
{/* ========== */}
        <div className={styled.blank}></div>
        <Header1 />
        <div className={styled.wrapper}>
          <div className={styled.text_wrapper}>
            <div className={styled.blank_2}></div>
            <div className={styled.big_text}>BE THE FIRST TO KNOW</div>
            <p className={styled.small_text}>
              Sign Up to our VIP Squad for the latest products, and exclusive
              discounts!
            </p>
            <img src={vactor} alt="..." className={styled.vactor} />

            <div className={styled.desktopImage}>
              <div className={styled.background_image}></div>
              <img src={vactor} alt="..." className={styled.vactor_1} />
            </div>
          </div>

          {/* //=========form section========== */}
          <div className={styled.form_wrapper}>
            <form className={styled.form} onSubmit={handleFormSubmit}>
              <div className={styled.label_inut}>
                <label className={styled.label}>Store Name</label>
                <input
                  type="text"
                  value={storeName}
                  name="storeName"
                  onChange={(e) => setStoreName(e.target.value)}
                  placeholder="Rahul Kumar"
                  className={styled.input}
                />
              </div>
              {/* ===== */}
              <div className={styled.label_inut}>
                <label className={styled.label}>E-Mail</label>
                <input
                  type="text"
                  value={email}
                  name="email"
                  onChange={(e) => setEmail(e.target.value)}
                  placeholder="rahul_kumar@gmail.com"
                  // className={styled.input}
                  className={`${styled.input} ${emailError ? styled.inputError : ""}`}
                  // pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
                />
                
<small className={styled.errorLine}>{emailError}</small>
              </div>
              {/* ===== */}
              <div className={styled.flex_two_input}>
                <div className={styled.flex_two_input_label}>
                  <label className={styled.label}>State</label>
                  <input
                    type="text"
                    value={state}
                    name="state"
                    onChange={(e) => setState(e.target.value)}
                    placeholder="Haryana"
                    className={styled.input_1}
                  />
                </div>
                {/* ==== */}
                <div className={styled.flex_two_input_label}>
                  <label
                    className={styled.label}
                    style={{ marginLeft: "25px" }}
                  >
                    City
                  </label>
                  <input
                    type="text"
                    value={city}
                    name="city"
                    onChange={(e) => setCity(e.target.value)}
                    placeholder="Gurugram"
                    className={styled.input_1}
                    style={{ marginLeft: "25px" }}
                  />
                </div>
              </div>
              {/* //button */}
              <button
                type="submit"
                className={`${styled.button} ${
                  inputDisabled() ? styled.disabled : ""
                }`}
                disabled={inputDisabled()}
              >
                Subscribe
              </button>
            </form>
            <div className={styled.allicon}>
              <a
                href="https://www.instagram.com/jcbl_accessories/?igshid=MzRlODBiNWFlZA%3D%3D"
                target="self"
                // onClick={() => handleIconClick("instagram")}
              >
                <span>
                  <img src={instagram} alt="..." />
                </span>
              </a>
              <a
                href="https://www.facebook.com/people/JCBL-Accessories/100089470508925/?mibextid=LQQJ4d"
                target="self"
                // onClick={() => handleIconClick("facebook")}
              >
                <span>
                  <img src={facebook} alt="..." />
                </span>
              </a>
              <a
                href="https://www.youtube.com/@jcblaccessoriesontube"
                target="self"
                // onClick={() => handleIconClick("youtube")}
              >
                <span>
                  <img src={youtube} alt="..." />
                </span>
              </a>
              <a
                href="https://jcblaccessories.com/"
                target="self"
                // onClick={() => handleIconClick("browser")}
              >
                <span>
                  <img src={browser} alt="..." />
                </span>
              </a>
            </div>
            <div className={styled.footer}></div>
          </div>
        </div>
      </div>
    </>
  ) : (
    <div className={styled.container}>
      <div className={styled.blank}></div>
      <Header1 />
      <div className={styled.wrapper}>
        <div className={styled.text_wrapper}>
          <div className={styled.blank_2}></div>
          <div className={styled.big_text}>BE THE FIRST TO KNOW</div>
          <p className={styled.small_text}>
            Sign Up to our VIP Squad for the latest products, and exclusive
            discounts!
          </p>
          <img src={vactor} alt="..." className={styled.vactor} />

          <div className={styled.desktopImage}>
            <div className={styled.background_image}></div>
            <img src={vactor} alt="..." className={styled.vactor_1} />
          </div>
        </div>

        {/* //=========form section========== */}
        <div className={styled.form_wrapper}>
          <form className={styled.form} onSubmit={handleFormSubmit}>
            <div className={styled.label_inut}>
              <label className={styled.label}>Store Name</label>
              <input
                type="text"
                value={storeName}
                name="storeName"
                onChange={(e) => setStoreName(e.target.value)}
                placeholder="Rahul Kumar"
                className={styled.input}
              />
            </div>
            {/* ===== */}
            <div className={styled.label_inut}>
              <label className={styled.label}>E-Mail</label>
              <input
                type="text"
                value={email}
                name="email"
                onChange={(e) => setEmail(e.target.value)}
                placeholder="rahul_kumar@gmail.com"
                // className={styled.input}
                className={`${styled.input} ${emailError ? styled.inputError : ""}`}
                // pattern="[^@ \t\r\n]+@[^@ \t\r\n]+\.[^@ \t\r\n]+"
              />
              <small className={styled.errorLine}>{emailError}</small>
            </div>
            {/* ===== */}
            <div className={styled.flex_two_input}>
              <div className={styled.flex_two_input_label}>
                <label className={styled.label}>State</label>
                <input
                  type="text"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value)}
                  placeholder="Haryana"
                  className={styled.input_1}
                />
              </div>
              {/* ==== */}
              <div className={styled.flex_two_input_label}>
                <label className={styled.label} style={{ marginLeft: "25px" }}>
                  City
                </label>
                <input
                  type="text"
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Gurugram"
                  className={styled.input_1}
                  style={{ marginLeft: "25px" }}
                />
              </div>
            </div>
            {/* //button */}
            <button
              type="submit"
              className={`${styled.button} ${
                inputDisabled() ? styled.disabled : ""
              }`}
              disabled={inputDisabled()}
            >
              Subscribe
            </button>
          </form>
          <div className={styled.allicon}>
            <a
              href="https://www.instagram.com/jcbl_accessories/?igshid=MzRlODBiNWFlZA%3D%3D"
              target="self"
              // onClick={() => handleIconClick("instagram")}
            >
              <span>
                <img src={instagram} alt="..." />
              </span>
            </a>
            <a
              href="https://www.facebook.com/people/JCBL-Accessories/100089470508925/?mibextid=LQQJ4d"
              target="self"
              // onClick={() => handleIconClick("facebook")}
            >
              <span>
                <img src={facebook} alt="..." />
              </span>
            </a>
            <a
              href="https://www.youtube.com/@jcblaccessoriesontube"
              target="self"
              // onClick={() => handleIconClick("youtube")}
            >
              <span>
                <img src={youtube} alt="..." />
              </span>
            </a>
            <a
              href="https://jcblaccessories.com/"
              target="self"
              // onClick={() => handleIconClick("browser")}
            >
              <span>
                <img src={browser} alt="..." />
              </span>
            </a>
          </div>
          <div className={styled.footer}></div>
        </div>
      </div>
    </div>
  );
};

export default VipSignup;
