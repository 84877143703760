import React, { useState } from 'react'
import couponCover from '../assets/cover.jpg';
// import ScratchCard from 'react-scratchcard';
import styles from './Rewards.module.css';
import useWindowSize from 'react-use/lib/useWindowSize'
import Confetti from 'react-confetti'
import ScratchCard from 'simple-scratch-card';

// const settings = {
//     width: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
//     height: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
//     image: couponCover,
//     finishPercent: 75,
//     // onComplete: () => console.log('The card is now clear!')
// };

const Rewards = () => {
    const [show, setShow] = useState(false)
    const [confetti, setConfetti] = useState(false)
    const { width, height } = useWindowSize()

    function copyToClipboard() {
        var textField = document.createElement('textarea')
        textField.innerText = 'FESTIVE15'
        document.body.appendChild(textField)
        textField.select()
        document.execCommand('copy')
        textField.remove()
    }
    return (
        <div className={styles.container}>
            <img className={styles.topLogo} src={require('../assets/JCBL.png')} alt='JCBL' />
            <div className={styles.itemWrapper}>
                <img className={styles.logoImg} src={require('../assets/logo512.png')} alt="glono" />
                <h1 className={styles.heading}>SCRATCH & WIN</h1>
                <ScratchCard
                    cover={couponCover}
                    percent={75}
                    width={window.innerWidth < 768 ? window.innerWidth * 0.8 : 400}
                    height={window.innerWidth < 768 ? window.innerWidth * 0.8 : 400}
                    onComplete={() => {
                        setShow(true)
                        setConfetti(true)
                        setTimeout(() => {
                            setConfetti(false)
                        }, 3000)
                    }}
                >
                    <img style={{
                        width: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
                        height: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
                    }} className={styles.coupon} src={require('../assets/inner.jpg')} alt="coupon" />
                </ScratchCard>
                {/* <ScratchCard {...settings} onComplete={() => {
                    setShow(true)
                    setConfetti(true)
                    setTimeout(() => {
                        setConfetti(false)
                    }, 3000)
                }}>
                    <img style={{
                        width: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
                        height: window.innerWidth < 768 ? window.innerWidth * 0.8 : 400,
                    }} className={styles.coupon} src={require('../assets/inner.jpg')} alt="coupon" />
                </ScratchCard> */}
                {show ? <button onClick={copyToClipboard} className={styles.linkText}>Copy Code</button> : null}
                {/* <button onClick={copyToClipboard} className={styles.button}><h3 className={styles.btnText}>Copy</h3></button> */}
                <h4>Use Code to get discount on the Glono app</h4>
                {show ? <div className={styles.textwrapper}>
                    <div className={styles.stepsHeading}>How to Redeem</div>
                    <ul className={styles.stepsList}>
                        <li className={styles.stepsItem}>Scratch the code.</li>
                        <li className={styles.stepsItem}>Download the app or visit the website</li>
                        <li className={styles.stepsItem}>Add to Cart product you want to buy</li>
                        <li className={styles.stepsItem}>Apply Coupon & you'll get flat percentage off</li>
                        <li className={styles.stepsItem}>Happy Shopping</li>
                    </ul>
                </div> : null}
                <button onClick={() => window.location.pathname = '/download_now'} className={styles.appBtn}><h3 className={styles.appBtnText}>Get App Now</h3></button>

                <p>For More Information, Visit Us at <a href="https://jcblaccessories.com/">https://jcblaccessories.com/</a></p>

            </div>
            <Confetti
                width={width}
                height={height}
                run={show}
                numberOfPieces={confetti ? 200 : 0}
            />
        </div>
    )
}

export default Rewards