import React, { useEffect, useState } from "react";
import styled from "../screens/newUserData.module.css";
import Header1 from "../components/Header_1";
import cross from "../assets/delete.png";
import popuplogo from "../assets/Group.png";
import { FaStarOfLife } from "react-icons/fa6";

const NewUserData = () => {
  const [firmname, setFirmname] = useState("");
  const [city, setCity] = useState("");
  const [state, setState] = useState("");
  const [pincode, setPincode] = useState("");
  const [mobile, setMobile] = useState("");
  const [poc, setPoc] = useState("");
  const [gst, setGst] = useState("");
  const [username, setUsername] = useState("");
  const [pocName, setPocName] = useState("");
  const [pocSecond, setPocSecond] = useState("");
  const [secondPocName, setSecondPocName] = useState("");

  const [formSubmit, setFormSubmit] = useState(false);
  const [gstError, setGstError] = useState(false);
  const [mobileError, setMobileError] = useState(false);
  const [pocError, setPocError] = useState(false);
  const [pocError_1, setPocError_1] = useState(false);
  const [pocError_2, setPocError_2] = useState(false);
  const [pinError, setPinError] = useState(false);

  const [selectedContact, setSelectedContact] = useState("");
  const [contactManagerNo, setContactManagerNo] = useState("");
  const [selectDataget, setSelectedDataget] = useState([]);

  const getSelected = async () => {
    const url = "https://dev-cms.glono.in/api/v2/website/bdm_dropdown";
    const response = await fetch(url);
    const data = await response.json();
    const sortingData = data.data.sort((a, b) =>
      a.poc_name.localeCompare(b.poc_name)
    );
    setSelectedDataget(sortingData);
    console.log(sortingData);
  };

  useEffect(() => {
    getSelected();
  }, []);

  const isValidGSTNo = (str) => {
    let regex = /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}Z[0-9A-Z]{1}$/;
    if (str == "") {
        return true;
    }
    return regex.test(str);
};


  const handleFormSubmit = (e) => {
    e.preventDefault();
    setGstError("");
    setMobileError("");
    setPocError("");
    setPocError_1("");
    setPocError_2("");
    setPinError("");
    const isMobileValid = /^\d{10}$/.test(mobile);
    const isPocValid = /^\d{10}$/.test(poc);
    // const isGstValid =
    //   "" ||
    //   /^[0-9]{2}[A-Z]{5}[0-9]{4}[A-Z]{1}[1-9A-Z]{1}[Z]{1}[0-9A-Z]{1}$/.test(
    //     gst
    //   );

    const isGstValid = isValidGSTNo(gst);
 

    const isPincodeValid = /^\d{6}$/.test(pincode);

    if (isMobileValid && isPincodeValid && isPocValid && isGstValid) {
      const requestOptions = {
        method: "POST",
        headers: {
          "Content-Type": "application/json",
        },
        body: JSON.stringify({
          firm_name: firmname,
          city: city,
          state: state,
          pincode: pincode.toString(),
          poc_phone: poc.toString(),
          poc_name: pocName,
          dealer_phone: mobile.toString(),
          gst: gst,
          user_id: username,
          secondary_poc_name: secondPocName,
          secondary_poc_phone: pocSecond.toString(),
          poc_manager_name: selectedContact,
          poc_manager_phone: contactManagerNo.toString(),
        }),
      };

      fetch("https://dev-cms.glono.in/api/v2/website/bdm_form", requestOptions)
        // fetch("#", requestOptions)
        .then((response) => response.text())
        .then((result) => {
          console.log(result);
          setFirmname("");
          setUsername("");
          setMobile("");
          setState("");
          setCity("");
          setGst("");
          setPoc("");
          setPocSecond("");
          setPocName("");
          setSecondPocName("");
          setPincode("");
          setFormSubmit(true);
          setContactManagerNo("");
          setSelectedContact("");
        })
        .catch((error) => {
          console.log("error", error);
        });
    } else {
      // If validation fails, set corresponding error messages
      if (!isMobileValid) {
        setMobileError("Please enter a valid 10-digit Mobile Number");
      }
      if (!isGstValid) {
        setGstError("Please enter a valid GST number");
      }
      if (!isPincodeValid) {
        setPinError("Please enter a valid 6-digit Pincode");
      }
      if (!isPocValid) {
        setPocError("Please enter a valid 6-digit Pincode");
        setPocError_1("Please enter a valid 6-digit Pincode");
        setPocError_2("Please enter a valid 6-digit Pincode");
      }
    }
  };

  const inputDisabled = () => {
    if (
      firmname === "" ||
      mobile === "" ||
      // gst === "" ||
      city === "" ||
      state === "" ||
      selectedContact === "" ||
      contactManagerNo === "" ||
      pincode === "" ||
      poc === "" ||
      pocName === ""
    ) {
      return true;
    }
    return false;
  };

  const handleContactChange = (e) => {
    const selectedName = e.target.value;
    setSecondPocName(selectedName);
    const selectedContactManager = selectDataget.find(
      (item) => item.poc_name === selectedName
    );
    if (selectedContactManager) {
      setPocSecond(selectedContactManager.poc_phone);
    } else {
      setPocSecond("");
    }
  };
  const handlePocChange = (e) => {
    const selectedName = e.target.value;
    setPocName(selectedName);
    const selectedContactManager = selectDataget.find(
      (item) => item.poc_name === selectedName
    );
    if (selectedContactManager) {
      setPoc(selectedContactManager.poc_phone);
      setContactManagerNo(selectedContactManager.poc_manager_phone);
      setSelectedContact(selectedContactManager.poc_manager_name);
    } else {
      setPoc("");
    }
  };

  return (
    <div>
      <div className={styled.container}>
        <Header1 />
        {formSubmit ? (
          <div className={styled.success_message}>
            <div className={styled.success_box}>
              <img src={popuplogo} alt="..." className={styled.success_icon} />
              <div className={styled.submitted}>Thank You</div>
              <div className={styled.submitted_2}>
                Your Form Submitted Successfully
              </div>
              <button
                onClick={() => setFormSubmit(false)}
                className={styled.delete}
              >
                <img src={cross} alt="..." />
              </button>
            </div>
          </div>
        ) : null}
        {/* //=========form section========== */}
        <div className={styled.form_wrapper}>
          <form className={styled.form} onSubmit={handleFormSubmit}>
            {/* ======================================================= */}
            <div className={styled.flex_two_input}>
              <div className={styled.label_inut}>
                <label className={styled.label}>
                  Firm Name{" "}
                  <FaStarOfLife size={6} color="red" className={styled.star} />
                </label>
                <input
                  type="text"
                  value={firmname}
                  name="firmname"
                  onChange={(e) => setFirmname(e.target.value)}
                  placeholder="Globe Automobiles Pvt. Ltd."
                  className={styled.input}
                />
              </div>
            </div>
            {/* ====================== */}
            <div className={styled.label_inut}>
              <label className={styled.label}>Customer Name</label>
              <input
                type="text"
                value={username}
                name="username"
                onChange={(e) => setUsername(e.target.value)}
                placeholder="Rahul Kumar"
                className={styled.input}
              />
            </div>
            {/* ============ */}
            <div className={styled.label_inut}>
              <label className={styled.label}>
                User Mobile No.{" "}
                <FaStarOfLife size={6} color="red" className={styled.star} />
              </label>
              <input
                type="text"
                value={mobile}
                name="mobile"
                onChange={(e) => setMobile(e.target.value)}
                placeholder="9999999999"
                className={`${styled.input} ${
                  mobileError ? styled.inputError : ""
                }`}
              />
              {/* <small className={styled.errorLine}>{mobileError}</small> */}
            </div>
            {/* ======= */}
            <div className={styled.label_inut}>
              <label className={styled.label}>GST No.</label>
              <input
                type="text"
                value={gst}
                name="gst"
                onChange={(e) => setGst(e.target.value)}
                placeholder="GGLPS8019SNJAS"
                className={`${styled.input} ${
                  gstError ? styled.inputError : ""
                }`}
              />
              {/* <small className={styled.errorLine}>{gstError}</small> */}
            </div>
            {/* ========= */}
            <div className={styled.label_inut}>
              <label className={styled.label}>
                Primary POC Name{" "}
                <FaStarOfLife size={6} color="red" className={styled.star} />
              </label>
              {/* <input
              type="text"
              value={pocName}
              name="poc"
              onChange={(e) => setPocName(e.target.value)}
              placeholder="Raman Kumar"
              className={styled.input}
            /> */}
              <select
                className={styled.selectedSection}
                value={pocName}
                onChange={handlePocChange}
                placeholder="Select Point of Contact - Name"
              >
                <option value="" className={styled.color}>
                  {!selectDataget
                    ? "Loading..."
                    : "Select Point of Contact - Name"}
                </option>
                {selectDataget.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.poc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styled.label_inut}>
              <label className={styled.label}>
                Primary POC - Phone No.{" "}
                <FaStarOfLife size={6} color="red" className={styled.star} />
              </label>
              <input
                type="text"
                value={poc}
                name="poc"
                onChange={(e) => setPoc(e.target.value)}
                placeholder="9999999999"
                className={`${styled.input} ${
                  pocError ? styled.inputError : ""
                }`}
              />
            </div>
            <div className={styled.label_inut}>
              <label className={styled.label}>Secondary POC - Name</label>
              {/* <input
              type="text"
              value={secondPocName}
              name="poc"
              onChange={(e) => setSecondPocName(e.target.value)}
              placeholder="Sagar Mishra"
              className={styled.input}
            /> */}
              <select
                className={styled.selectedSection}
                value={secondPocName}
                onChange={handleContactChange}
              >
                <option value="" className={styled.color}>
                  Select Secondary POC
                </option>
                {selectDataget.map((item, index) => (
                  <option key={index} value={item.name}>
                    {item.poc_name}
                  </option>
                ))}
              </select>
            </div>
            <div className={styled.label_inut}>
              <label className={styled.label}>Secondary POC - Phone No.</label>
              <input
                type="text"
                value={pocSecond}
                name="poc"
                onChange={(e) => setPocSecond(e.target.value)}
                placeholder="9999999999"
                className={`${styled.input} ${
                  pocError_1 ? styled.inputError : ""
                }`}
              />
            </div>

            <div className={styled.wrapper_three_coloum}>
              {/* ===== */}
              <div className={styled.label_inut}>
                <label className={styled.label}>
                  City{" "}
                  <FaStarOfLife size={6} color="red" className={styled.star} />
                </label>
                <input
                  type="text"
                  value={city}
                  name="city"
                  onChange={(e) => setCity(e.target.value)}
                  placeholder="Gurugram"
                  className={styled.input_1}
                />
              </div>
              {/* ===== */}
              <div className={styled.label_inut}>
                <label className={styled.label}>
                  State{" "}
                  <FaStarOfLife size={6} color="red" className={styled.star} />
                </label>
                <input
                  type="text"
                  value={state}
                  name="state"
                  onChange={(e) => setState(e.target.value)}
                  placeholder="Haryana"
                  className={styled.input_1}
                />
              </div>
              {/* ==== */}
              <div className={styled.label_inut}>
                <label className={styled.label}>
                  Pincode{" "}
                  <FaStarOfLife size={6} color="red" className={styled.star} />
                </label>
                <input
                  type="text"
                  value={pincode}
                  name="pincode"
                  onChange={(e) => setPincode(e.target.value)}
                  placeholder="122004"
                  className={`${styled.input_1} ${
                    pinError ? styled.inputError : ""
                  }`}
                />
                {/* <small className={styled.errorLine}>{pinError}</small> */}
              </div>
            </div>

            {/* =========contact manager flied=============== */}
            <div className={styled.label_inut}>
              <label className={styled.label}>
                Contact Manager - Name{" "}
                <FaStarOfLife size={6} color="red" className={styled.star} />
              </label>
              {/* <select
              className={styled.selectedSection}
              value={selectedContact}
              onChange={handleContactChange}
            >
              <option value="">Select Contact Manager</option>
              {selectDataget.map((item, index) => (
                <option key={index} value={item.name}>
                  {item.poc_manager_name}
                </option>
              ))}
            </select> */}
              <input
                type="text"
                value={selectedContact}
                name="contact manager"
                onChange={(e) => setSelectedContact(e.target.value)}
                placeholder="Sagar Mishra"
                className={styled.input}
              />
            </div>
            <div className={styled.label_inut}>
              <label className={styled.label}>
                Contact Manager - Phone No.{" "}
                <FaStarOfLife size={6} color="red" className={styled.star} />
              </label>
              <input
                type="text"
                value={contactManagerNo}
                name="poc"
                onChange={(e) => setContactManagerNo(e.target.value)}
                placeholder="9999999999"
                className={`${styled.input} ${
                  pocError_2 ? styled.inputError : ""
                }`}
              />
            </div>

            {/* //button */}
            <button
              type="submit"
              className={`${styled.button} ${
                inputDisabled() ? styled.disabled : ""
              }`}
              // disabled={inputDisabled()}
              disabled={inputDisabled() || formSubmit}
            >
              {formSubmit ? "Submitting..." : "Submit"}
            </button>
          </form>
          {/* ==========================all icon=================== */}
        </div>
      </div>
    </div>
  );
};

export default NewUserData;
